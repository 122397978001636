import React from "react";
import { QRCodeSVG, QRCodeCanvas } from "qrcode.react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";

const QRGenerator = ({ videoId, fgColor, icon }) => {
  const [color, setColor] = useState(fgColor ?? "#000000");
  const [format, setFormat] = useState("svg");
  const [downloadUrl, setDownloadUrl] = useState("");

  const svgRef = useRef();
  const canvasRef = useRef();

  const url = `https://tvin.mx/qr/${videoId}`;

  const downloadSvg = () => {
    const svg = svgRef.current;

    const serializer = new XMLSerializer();
    let source = serializer.serializeToString(svg);

    if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns="http://www.w3.org/2000/svg"'
      );
    }
    if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
      );
    }

    source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

    setDownloadUrl(
      "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source)
    );
  };

  useEffect(() => {
    console.log(format)
    
    if (format === "png") {
      const canvas = canvasRef.current;
      const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      setDownloadUrl(image)
    } else {
      downloadSvg()
    }
  }, [color, format]);

  return (
    <div className="row">
      <div className="col-3 offset-3 d-flex align-items-center flex-column">
        <QRCodeSVG ref={svgRef} fgColor={color} value={url} />
        <QRCodeCanvas
          ref={canvasRef}
          className="d-none"
          fgColor={color}
          value={url}
          size={800}
        />

        <a href={downloadUrl} className="mt-2" download={`video-${videoId}-QR`}>
          Descargar
        </a>
      </div>
      <div className="col-2">
        <label className="form-label">Color</label>
        <input
          name="qr_code[color]"
          className="form-control mb-3"
          type="color"
          value={color}
          onChange={({ target }) => setColor(target.value)}
        />

        <label className="form-label">Formato</label>
        <select className="form-control" onChange={({ target }) => setFormat(target.value)}>
          <option value="svg">SVG</option>
          <option value="png">PNG</option>
        </select>
        <input type="hidden" value={videoId} name="qr_code[ott_video_id]" />
      </div>
    </div>
  );
};

export default QRGenerator;
