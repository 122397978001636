import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import autocompleteSearch from "./autocomplete";

const SearchBar = ({ searchData, name, searchBtn }) => {
  const [addSuggest, setAddSuggest] = useState(true);
  const [showBtn, setShowBtn] = useState(false);
  const input = useRef();

  const handleSuggestions = (e) => {
    if (addSuggest) {
      autocompleteSearch(searchData, e.target, name);
      setAddSuggest(false);
    }
  };

  const handleSearch = () => {
    window.location.search = `?${name}=${encodeURIComponent(
      input.current.value
    )}`;
  };

  return (
    <>
      <input
        ref={input}
        type="text"
        id="autoComplete"
        placeholder="Buscar"
        onFocus={() => setShowBtn(true)}
        onClick={(e) => handleSuggestions(e)}
        autoComplete="off"
        name={name}
      />
      {searchBtn && showBtn && (
        <button type="button" className="search-btn" onClick={handleSearch}>
          Buscar
        </button>
      )}
    </>
  );
};

SearchBar.propTypes = {
  searchData: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
};

export default SearchBar;
